.modal-content {
  border-radius: 1.3rem !important;
}
.modal-header {
  padding: 0.7rem 1rem 0.1rem 1rem !important;
}
.modal-body {
  padding: 0 !important;
}
/* p {
  margin: 10px;
} */
.modal-footer {
  cursor: pointer;
  border-bottom-left-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
}
.modal-footer:hover {
  background-color: rgb(226, 231, 231);
}
