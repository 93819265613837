.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .bDcFxZ {
  width: 100%;
  height: 50% !important;
} */
.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

/* info window */
.pxp-price-marker {
  position: absolute;
  font-family: "Regular", sans-serif;
  color: "#333";
  border: 2px solid #333;
  background-color: #fff;
  padding: 6px 10px;
  font-weight: bold;
  border-radius: 0.3rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  cursor: pointer;
  -webkit-transform: translate(-50%, calc(-100% - 6px));
  transform: translate(-50%, calc(-100% - 6px));
}
.pxp-dark-mode .pxp-price-marker {
  color: #fff;
  border: 2px solid #fff;
  background-color: #000;
}
.pxp-price-marker:after {
  content: " ";
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  margin-left: -5px;
  width: 9px;
  height: 9px;
  background: #fff;
  border-right: 2px solid #333;
  border-bottom: 2px solid #333;
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.pxp-dark-mode .pxp-price-marker:after {
  background: #000;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.pxp-price-marker:hover,
.pxp-price-marker.active {
  border-color: #fff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
  padding: 0;
  z-index: 3;
}
.pxp-dark-mode .pxp-price-marker:hover,
.pxp-dark-mode .pxp-price-marker.active {
  border-color: #000;
}
.pxp-price-marker:hover:after,
.pxp-price-marker.active:after {
  border-color: #fff;
}
.pxp-dark-mode .pxp-price-marker:hover:after,
.pxp-dark-mode .pxp-price-marker.active:after {
  border-color: #000;
}
.pxp-price-marker:hover .pxp-marker-short-price,
.pxp-price-marker.active .pxp-marker-short-price {
  display: none;
}
.pxp-price-marker .pxp-marker-details {
  display: none;
}
.pxp-price-marker:hover .pxp-marker-details,
.pxp-price-marker.active .pxp-marker-details {
  display: block;
}
.pxp-marker-details-fig {
  width: 72px;
  height: 72px;
  border-radius: 0.3rem 0 0 0.3rem;
  float: left;
}
.pxp-marker-details-info {
  float: left;
  font-size: 14px;
}
.pxp-marker-details-info-title {
  font-size: 12px;
  padding: 10px 10px 2px 10px;
  color: #333;
  font-weight: normal;
}
.pxp-dark-mode .pxp-marker-details-info-title {
  color: #fff;
}
.pxp-marker-details-info-price {
  font-size: 14px;
  padding: 0 10px 5px 10px;
  color: #333;
}
.pxp-dark-mode .pxp-marker-details-info-price {
  color: #fff;
}
.pxp-marker-details-info-feat {
  font-weight: normal;
  padding: 0 10px;
  color: #333;
  font-size: 11px;
  opacity: 0.7;
}
.pxp-dark-mode .pxp-marker-details-info-feat {
  color: #fff;
}
.pxp-marker-details-info-feat > span {
  display: inline-block;
  margin: 0 7px;
}
.pxp-marker-cluster {
  font-family: "Regular", sans-serif;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 100px;
  color: #fff;
  font-size: 12px;
}
.pxp-marker-cluster:hover {
  background: rgba(0, 0, 0, 1);
  z-index: 3;
}
