html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

body {
  padding: 0;
  /* font-family: sans-serif; */
  font-family: "PT Sans","Roboto Condensed", sans-serif;



}

/* Example: fonts.css */

@font-face {
  font-family: 'Roboto Condensed';
  src: url('./fonts/EnnVisions.otf') format('truetype');

}

.navbar-nav-inner-section {
  margin-right: 50px;
  margin-top: 5px;
}

.mobile_menu {
  display: none;
}

@media screen and (max-width: 990px) {
  .mobile_menu {
    display: inline;
  }

  .location-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .location-section {
    margin-top: 20px;
  }
}

/* Modal */
.var_detail_modal .flex {
  color: black;
}

.var_detail_modal .flex-t {
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.var_detail_modal .flex-p {
  color: black;
  font-size: 14px;
  line-height: normal;
}

.var_detail_modal .location {
  color: blue;
}

.var_detail_modal .user-image {
  height: 86px;
  width: 86px;
  margin-right: 10px;
  border-radius: 15px;
}

.var_detail_modal .model-body {
  display: flex;
}

.var_detail_modal .flex-link {
  font-size: 13px;

  text-decoration: none;
}

.var_detail_modal .flex-call {
  font-size: 13px;
  text-decoration: none;
  color: black;
}

.var_detail_modal .location {
  font-size: 14px;
}

.modal_upper_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.modal_image_section {
  display: inline-block;
  flex: 1;
}

.modal_information_section {
  display: inline-block;
  margin-left: 10px;
  position: absolute;
  flex: 2;
}

.modal_location_section {
  flex: 3;
}

@media screen and (max-width: 991px) {
  .location-map > div {
    height: 650px !important;
  }

  footer {
    position: fixed !important;
    height: auto !important;
  }
}

@media screen and (width<760px) {
  .location-map > div {
    height: 200px !important;
  }

  footer {
    position: fixed !important;
    height: auto !important;
  }
}
.location-common-btn {
  background-color: #619f32;
  color: #fff;
  font-size: 18px;
  font-family: "Roboto Condensed", sans-serif;
  bottom: 40px;
  max-width: 409px;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.manageLocationProduct {
  cursor: pointer;
}

.checkOutTwoAddCart {
  cursor: pointer;
  /* height: 200px; */
}

input[type="file"] {
  display: none;
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

page12-container {
  box-shadow: 0px 0px 4px #0000005c;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  padding-bottom: 15px;
  border-radius: 5px;
}

.page12-container .logo-body-text img {
  width: 120px;
  margin-bottom: 20px;
}

.page12-container .btn-container button {
  width: 190px;
  height: 43px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
}

@media (max-width: 767px) {
  .page12-custom {
    margin-left: 15px;
    margin-right: 15px;
  }
}
